if ('undefined' === typeof vf) {
    vf = {};
}

vf.Ecommerce = {};

var jQeCommmerceFactory = {

    opt: {
        urls: {
            'seltoken': '/api/commerce//extAuthInt/oauth2/token',
            'setcookiesel': '/api/commerce//setcookie/sel',
            'setcookiebshop': '/api/commerce//setcookie/bshop',
        }
    },

    _setBshopCookie: function (xhr) {
        var deferred = $.Deferred();
        if (!(xhr.getResponseHeader('x-vf-bshoptoken-set') && '' !== xhr.getResponseHeader('x-vf-bshoptoken-set'))) {
            deferred.resolve();
        } else {
            var setCookie = $.ajax({
                method: 'POST',
                url: this.opt.urls.setcookiebshop,
                headers: {
                    'x-vf-bshoptoken': xhr.getResponseHeader('x-vf-bshoptoken-set')
                },
                cache: false
            }).then(function (result) {
                //throw response away since cookie was successfully set - go ahead with normal ajax call
                deferred.resolve();
            }).fail(function (error) {
                deferred.reject({ 'error': 'something went wrong' });
            });
        }
        return deferred.promise();
    },

    _getToken: function () {
        var deferred = $.Deferred();
        $.ajax({
            method: 'POST',
            timeout: 20000,
            cache: false,
            headers: {
                'Authorization': 'Basic MmRqbXdDemlBbGk1ZkI2UEtCc0FnWnJ1OVhHOTNqdEU6RTNMdUlWV2MzUWFuMmoxVQ==',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: {
                grant_type: 'client_credentials',
                apix_opcoid: '49',
                scope: 'bshop_all'
            },
            transformRequest: function (obj) {
                var str = [];
                for (var p in obj)
                {str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));}
                return str.join('&');
            },
            url: this.opt.urls.seltoken
        }).then(function (response) {
            var accessToken = response.access_token;
            deferred.resolve(accessToken);
        }).fail(function () {
            deferred.reject();
        });
        return deferred.promise();
    },

    _setSelToken: function (token) {
        var deferred = $.Deferred();
        $.ajax({
            method: 'POST',
            url: this.opt.urls.setcookiesel,
            headers: {
                'x-vf-seltokenbshop': token
            },
        }).then(function () {
            deferred.resolve();
        }).fail(function () {
            //i dont know why in diffentrent cirsumstances i have to resolve instead of reject
            deferred.resolve();
        }).done(function () {
        });
        return deferred.promise();
    }
};



(function () {

    vf.Ecommerce = function (apiFactory) {
        var ecommerceURL = '/api/commerce/';
        var nbaData = null;


        var callSel = function () {

            var deferred = $.Deferred();

            apiFactory._getToken().then(function (token) {
                apiFactory._setSelToken(token).then(function () {
                    deferred.resolve();
                });
            }).fail(function () {
                deferred.reject();
            });
            return deferred.promise();
        };


        var apiCall = function (cb) {

            deferred = $.Deferred();

            cb().then(function (text, status, xhr) {
                apiFactory._setBshopCookie(xhr).then(function () {
                    deferred.resolve(JSON.parse(xhr.responseText));
                }).fail(function () {
                    deferred.resolve(JSON.parse(xhr.responseText));
                });

            }).fail(function (xhr, response) {
                if (403 === xhr.status) {
                    callSel().then(function () {
                        apiCall(cb);
                    });

                } else if (401 === xhr.status) {
                    deferred.reject(xhr);
                }
                else {
                    deferred.reject(xhr);
                }
            });

            return deferred.promise();

        };


        var loginService = function (params) {
            if ('undefined' !== typeof params.username) {
                var str = params.username.toString();
                if ('number' !== typeof str) {
                    if ('0' === str.charAt(0)) {
                        str = str.slice(1);
                    }
                    str = str.replace(/[^0-9]/g, '').toString();
                    params.username = 'GSM' + str;
                }
            }

            return $.ajax({
                dataType: 'json',
                method: 'PUT',
                timeout: 600000,
                data: JSON.stringify(params),
                url: ecommerceURL + 'authstatus',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                cache: 'false'
            });
        };


        var nbaService = function () {
            return $.ajax({
                dataType: 'json',
                method: 'GET',
                url: ecommerceURL + 'nba',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': ''
                },
                cache: 'false'
            });
        };

        return {
            getNBA: function () {
                var defer = $.Deferred();

                apiCall(function () {
                    return nbaService().done(function (response) {
                        nbaData = response;
                    });
                }).done(function (response) {
                    defer.resolve();
                }).fail(function (obj) {
                    defer.reject();
                });

                return defer;
            },
            authentificate: function (msisdn, kk) {
                var defer = $.Deferred();
                apiCall(function () {
                    return loginService({ 'username': msisdn, 'authType': 'KIAS', 'password': kk });
                }).done(function (response) {
                    defer.resolve();
                }).fail(function (response) {
                    defer.reject(response);
                });
                return defer;
            },

            reAuthorize: function (msisdn) {

                var defer = $.Deferred();

                loginService({ 'username': 'GSM' + str, 'authType': 'USSA' }).done(function (response) {
                    defer.resolve();
                }).fail(function (response) {
                    defer.reject();
                });

                return defer;
            },
            logout: function () {
                var defer = $.Deferred();

                loginService({ 'authType': 'logout' }).done(function (response) {
                    defer.resolve();
                }).fail(function (response) {
                    defer.reject();
                });

                return defer;
            },
            isCustomerRedPlusEligable: function () {
                var defer = $.Deferred();
                if (null === nbaData) {
                    defer.reject({
                        description: 'nba-call needed'
                    });
                } else {
                    if ('O' === nbaData.redplusEligibility.memberType) {
                        defer.resolve();
                    } else {
                        defer.reject({
                            description: 'nba-call needed',
                            status: '404.1'
                        });
                    }
                }
                return defer;
            },
            hasCustomerNoFF: function () {
                var defer = $.Deferred();
                if (null === nbaData) {
                    defer.reject({
                        description: 'nba-call needed'
                    });
                } else {
                    if ('Y' === nbaData.hasFF.toUpperCase()) {
                        defer.reject({
                            description: 'customer has ff',
                            status: '404.2'
                        });
                    } else {
                        defer.resolve();
                    }
                }
                return defer;
            },


            getMSISDN: function () {
                if (null !== nbaData) {
                    return nbaData.MSISDN;
                }
                return null;
            }
        };
    };
})(vf);
